import { Close } from "@/components/Close/Close";
import { useStores } from "@/stores";
import { ClientOnly, useI18n } from "@jog/react-components";
import { localStorage } from "@jog/shared";
import { PrismicRichText } from "@prismicio/react";
import classNames from "classnames";
import { useSession } from "next-auth/react";
import { useMemo, useState } from "react";
export const MarketingMessageBase = ({ message, className }) => {
    const [displayStatus, setDisplayStatus] = useState(true);
    const { userStore } = useStores();
    const session = useSession();
    const name = useMemo(() => { var _a, _b, _c; return localStorage.getItem("name") || ((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.name) || ((_c = (_b = session.data) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.name); }, [session, userStore.userData]);
    const { $t } = useI18n();
    const isUserLoggedIn = useMemo(() => session.status === "authenticated", [session]);
    return (<div className={classNames(className, "my-5 bg-white p-1.25", displayStatus ? "block" : "hidden")}>
            <div className="flex items-center justify-between">
                <div className={classNames("flex flex-wrap items-center pl-2.5 pr-5 text-base uppercase text-gray-base")}>
                    <ClientOnly>
                        {isUserLoggedIn && name && (<span>
                                {$t("Hello")} {name}
                                ,&nbsp;
                            </span>)}
                    </ClientOnly>
                    <PrismicRichText field={message}/>
                </div>
                <span onClick={() => setDisplayStatus(false)}>
                    <Close color="black" className="text-black"/>
                </span>
            </div>
        </div>);
};
export const MarketingMessage = ({ slice: { primary }, className }) => (<MarketingMessageBase className={className} message={primary.message}/>);
