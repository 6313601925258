import { Home } from "@/components/Home";
import Layout from "@/layout";
import { getFeaturedProducts } from "@/lib/prismicio/featuredProducts";
import { fetchHome } from "@/lib/prismicio/home";
import { getPage } from "@/lib/ssg";
import { useWishlist } from "@/lib/store/wishlist";
import { FeaturedProductsContext } from "@jog/react-components";
import { fetchS3HomePage, getDomains, retry } from "@jog/shared";
import { LogoLoader } from "components";
import { staticPage } from "lib";
const IndexPage = ({ data: { prismicContent, products, paths } }) => {
    const actionLoading = useWishlist.use.actionPending();
    return (<Layout gtmTypeOfPage="home">
            <FeaturedProductsContext.Provider value={{ products }}>
                {actionLoading && <LogoLoader />}
                <Home prismic={prismicContent.data} paths={paths}/>
            </FeaturedProductsContext.Provider>
        </Layout>);
};
export async function getStaticProps(context) {
    return staticPage(context, async () => {
        const prismicContent = await retry(() => context.preview && context.previewData
            ? fetchHome(context.previewData)
            : fetchS3HomePage());
        const products = (await getFeaturedProducts(prismicContent, getPage)) || [];
        const paths = getDomains().map(({ domain, locale }) => ({ url: domain.replace(/\/$/, ""), locale }));
        return { prismicContent, products, paths };
    });
}
export default IndexPage;
